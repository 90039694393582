import { useEffect, useState } from 'react'

const USER_INPUT_DEBOUNCE_TIME = 500

export const useDebouncedInput = <T>(
  input: T,
  debounceTime = USER_INPUT_DEBOUNCE_TIME
): {
  isBouncing: boolean
  debouncedValue: T
} => {
  const [isBouncing, setIsBouncing] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState(() => input)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(input)
      setIsBouncing(false)
    }, debounceTime)
    setIsBouncing(true)
    return () => {
      clearTimeout(handler)
    }
  }, [input, debounceTime])

  return { isBouncing, debouncedValue }
}
