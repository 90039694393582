import { useEffect, useState, useCallback } from 'react'

export const useLocalStorage = ({ key, initialValue }) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (window?.localStorage) {
      const storedItem = JSON.parse(window.localStorage.getItem(key))
      if (storedItem != null) {
        setValue(storedItem)
      }
    }
  }, [key])

  const setLocalStorage = useCallback(
    (newValue: any) => {
      if (window?.localStorage) {
        window.localStorage.setItem(key, JSON.stringify(newValue))
        setValue(newValue)
      }
    },
    [key]
  )

  return [value, setLocalStorage]
}
