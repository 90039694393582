import { addDays } from 'date-fns'
import { useCallback, useState } from 'react'
import z from 'zod'

const KEY = 'expertCommisions'

const ExpertCommissionSchema = z.object({
  p: z.coerce.number(),
  s: z.string(),
  e: z.coerce.date(),
})

type ExpertCommission = z.infer<typeof ExpertCommissionSchema>

const getCommissions = () => {
  let result: ExpertCommission[] = []
  if (typeof window !== 'undefined') {
    const { localStorage } = window
    const storedData = localStorage?.getItem(KEY)
    let parsedData = []
    try {
      parsedData = JSON.parse(storedData)
    } catch {
      /* empty */
    }

    const parseResult = z.array(ExpertCommissionSchema).safeParse(parsedData)
    result = parseResult.success ? parseResult.data : result
  }
  return result
}

const updateCommissions = (updatedData: ExpertCommission[]) => {
  const stringifiedValue = JSON.stringify(
    updatedData.map(({ e, ...rest }) => ({
      e: e.toISOString(),
      ...rest,
    }))
  )

  localStorage.setItem(KEY, stringifiedValue)
}

export const useExpertShowroomCommission = () => {
  const [expertCommissions, setExpertCommissions] = useState(getCommissions())

  const addExpertCommission = useCallback(
    (expertSlug: string, productId: number) => {
      const commissions = getCommissions()
      const otherItems = commissions.filter(({ p }) => productId !== p)

      const updatedData = [
        ...otherItems,
        {
          p: productId,
          e: addDays(new Date(), 60), // 60 days lookback
          s: expertSlug,
        },
      ]

      updateCommissions(updatedData)
      setExpertCommissions(updatedData)
    },
    []
  )

  const removeExpertCommision = useCallback((productId: number) => {
    const commissions = getCommissions()
    const updatedData = commissions.filter(({ p }) => productId !== p)

    updateCommissions(updatedData)
    setExpertCommissions(updatedData)
  }, [])

  return { expertCommissions, addExpertCommission, removeExpertCommision }
}
