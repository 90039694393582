import {
  useCreateClientAddressMutation,
  useUpdateClientAddressMutation,
  useDeleteClientAddressMutation,
} from 'types/graphql-generated'

export const useClientAddressMutations = () => {
  const [
    executeCreateClientAddress,
    { loading: isCreateClientAddressLoading },
  ] = useCreateClientAddressMutation()
  const [
    executeUpdateClientAddress,
    { loading: isUpdateClientAddressLoading },
  ] = useUpdateClientAddressMutation()
  const [
    executeDeleteClientAddress,
    { loading: isDeleteClientAddressLoading },
  ] = useDeleteClientAddressMutation()

  return {
    executeCreateClientAddress,
    executeUpdateClientAddress,
    executeDeleteClientAddress,
    isAddressLoading:
      isCreateClientAddressLoading ||
      isUpdateClientAddressLoading ||
      isDeleteClientAddressLoading,
  }
}
