import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { useEffect, useRef, useState } from 'react'

export const useLightbox = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const lightboxRef = useRef(null)

  useEffect(() => {
    if (lightboxRef.current) {
      if (isLightboxOpen) {
        disableBodyScroll(lightboxRef.current)
      } else {
        enableBodyScroll(lightboxRef.current)
      }
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isLightboxOpen])

  const openLightbox = () => {
    setIsLightboxOpen(true)
  }

  const closeLightbox = () => {
    setIsLightboxOpen(false)
  }

  return {
    isLightboxOpen,
    openLightbox,
    closeLightbox,
    lightboxRef,
  }
}
