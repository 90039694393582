import { remToPx } from 'polished'
import { useCallback } from 'react'
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE_FULL,
} from 'constants/common'
import { useMedia } from 'useMedia'

const getParentRef = <Parent>(ref?: React.RefObject<Parent>) => {
  if (ref?.current) {
    return ref.current
  }
  if (window) {
    return window
  }
  return null
}

const getHeaderScrollOffset = (isMobile) => {
  const offset = isMobile ? HEADER_HEIGHT_MOBILE_FULL : HEADER_HEIGHT_DESKTOP
  return parseInt(remToPx(offset))
}

interface UseScrollToProps<Parent extends HTMLElement> {
  containerRef?: React.RefObject<Parent>
  behavior?: ScrollBehavior
  withHeaderOffset?: boolean
}

export const useScrollTo = <
  Target extends HTMLElement = HTMLElement,
  Parent extends HTMLElement = HTMLElement,
>({
  containerRef,
  behavior = 'smooth',
  withHeaderOffset = false,
}: UseScrollToProps<Parent> = {}): {
  scrollToRef: (ref: React.RefObject<Target>) => void
} => {
  const { MOBILE: isMobile } = useMedia()
  const scrollToRef = useCallback(
    (targetRef: React.RefObject<Target>) => {
      const parentRef = getParentRef(containerRef)
      if ((parentRef || parentRef instanceof Window) && targetRef?.current) {
        parentRef.scroll({
          top:
            targetRef.current.offsetTop -
            (withHeaderOffset ? getHeaderScrollOffset(isMobile) : 0),
          behavior,
        })
      }
    },
    [containerRef, withHeaderOffset, behavior, isMobile]
  )

  return { scrollToRef }
}
