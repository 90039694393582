import { useRouter } from 'next/router'
import { useCallback } from 'react'

interface Props {
  steps: {
    label: string
    link: string
    validationSchemaIndex?: number
  }[]
  useShallowRouting?: boolean
}

export const useStepper = ({ steps, useShallowRouting = false }: Props) => {
  const router = useRouter()
  const activeStepIndex = steps.findIndex(
    ({ link }) => link === router.pathname
  )

  const goToStep = useCallback(
    ({
      index,
      useReplace = false,
    }: {
      index: number
      useReplace?: boolean
    }) => {
      if (index < 0 || index >= steps.length) {
        return
      }

      const routerAction = useReplace ? router.replace : router.push

      routerAction(steps[index].link, undefined, {
        shallow: useShallowRouting,
        scroll: true,
      })
    },
    [router.push, router.replace, steps, useShallowRouting]
  )

  return {
    activeStepIndex: activeStepIndex > -1 ? activeStepIndex : 0,
    goToStep,
  }
}
