import {
  CardElement,
  type useStripe,
  type useElements,
} from '@stripe/react-stripe-js'
import { useCallback } from 'react'
import { useSetupClientPaymentMethodMutation as useGraphQlSetupClientPaymentMethodMutation } from 'types/graphql-generated'

export const useSetupClientPaymentMethodMutation = (): [
  ({
    name,
    stripe,
    elements,
  }: {
    name: string
    stripe: ReturnType<typeof useStripe>
    elements: ReturnType<typeof useElements>
  }) => Promise<void>,
  { isLoading: boolean },
] => {
  const [executeSetupPaymentMutation, { loading: isLoading }] =
    useGraphQlSetupClientPaymentMethodMutation()

  const execute = useCallback(
    async ({ name, stripe, elements }) => {
      const {
        data: { setupClientPaymentMethod },
      } = await executeSetupPaymentMutation()

      return stripe.confirmCardSetup(setupClientPaymentMethod, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      })
    },
    [executeSetupPaymentMutation]
  )

  return [
    execute,
    {
      isLoading,
    },
  ]
}
