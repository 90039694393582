import { endOfMonth, startOfMonth } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useState } from 'react'
import {
  PUBLIC_SESSION_DURATION_DIFFERENCE_IN_MINUTES,
  SESSION_TYPE,
} from 'constants/common'
import { useUserAuth } from 'providers/userAuth'
import {
  type FirstPublicExpertAvailabilityQuery,
  type PublicExpertAvailabilitiesQuery,
  useFirstPublicExpertAvailabilityQuery,
  usePublicExpertAvailabilitiesQuery,
  type ClientExpertSessionQuery,
} from 'types/graphql-generated'

export const useBookingModalAvailabilitiesQuery = ({
  expertSession,
  displayedDate,
  selectedDate,
}: {
  expertSession: ClientExpertSessionQuery['clientExpertSession']
  displayedDate: Date
  selectedDate: Date
}) => {
  const userAuth = useUserAuth()

  const [firstAvailability, setFirstAvailability] =
    useState<
      FirstPublicExpertAvailabilityQuery['firstPublicExpertAvailability']
    >(null)
  const [availabilities, setAvailabilities] = useState<
    PublicExpertAvailabilitiesQuery['publicExpertAvailabilities']
  >([])
  const [availabilitiesInZonedTime, setAvailabilitiesInZonedTime] = useState<
    {
      activeDateObject: Date
      item: PublicExpertAvailabilitiesQuery['publicExpertAvailabilities'][number]
    }[]
  >([])

  const { loading: isFirstPublicExpertAvailabilityLoading } =
    useFirstPublicExpertAvailabilityQuery({
      skip: !expertSession || !selectedDate,
      variables: {
        expertSessionId: expertSession?.id,
      },
      onCompleted: ({ firstPublicExpertAvailability }) => {
        setFirstAvailability(firstPublicExpertAvailability)
      },
    })

  const { loading: isPublicExpertAvailabilityLoading } =
    usePublicExpertAvailabilitiesQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      skip: !expertSession,
      variables: {
        input: {
          duration:
            (expertSession?.lengthInMinutes || 0) +
            PUBLIC_SESSION_DURATION_DIFFERENCE_IN_MINUTES,
          expertUserId: expertSession?.expertUser.id,
          date: {
            from: userAuth
              ? userAuth.convertZonedDateToUTC(
                  startOfMonth(
                    userAuth.convertDateToTimezone(
                      zonedTimeToUtc(
                        displayedDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    )
                  )
                )
              : zonedTimeToUtc(
                  startOfMonth(displayedDate),
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            to: userAuth
              ? userAuth.convertZonedDateToUTC(
                  endOfMonth(
                    userAuth.convertDateToTimezone(
                      zonedTimeToUtc(
                        displayedDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    )
                  )
                )
              : zonedTimeToUtc(
                  endOfMonth(displayedDate),
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
          },
          sessionType: expertSession?.sessionType,
          ...(expertSession?.sessionType === SESSION_TYPE.GROUP
            ? { expertSessionId: expertSession.id }
            : {}),
        },
      },
      onCompleted: ({ publicExpertAvailabilities }) => {
        setAvailabilities(publicExpertAvailabilities)
        setAvailabilitiesInZonedTime(
          publicExpertAvailabilities.map((availability) => ({
            activeDateObject: userAuth?.convertDateToTimezone(
              new Date(availability.startAtUtc.isoFormat)
            ),
            item: availability,
          }))
        )
      },
    })

  return {
    availabilities,
    availabilitiesInZonedTime,
    firstAvailability,
    isPublicExpertAvailabilityLoading,
    isFirstPublicExpertAvailabilityLoading,
  }
}
