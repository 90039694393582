import { set } from 'lodash/fp'
import { useCallback } from 'react'
import { useGetSet } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import type { Scalars } from 'types/graphql-generated'

type FileData = {
  attachmentId?: string
  id: number
  name?: string
  url: string
  note?: string
  clientId?: string
  isError?: boolean
  file?: Scalars['Upload']
}

export const useFilesStateManagement = ({
  maxFilesCount,
  hasAbortController = false,
}: {
  maxFilesCount?: number
  hasAbortController?: boolean
} = {}) => {
  const [getFiles, setFiles] = useGetSet(new Array<FileData>())

  const setInitialFiles = useCallback(
    (newFiles: FileData[]) => {
      setFiles(
        newFiles.map((item) => ({
          ...item,
          clientId: uuidv4(),
        }))
      )
    },
    [setFiles]
  )

  const setNewFilesToUpload = useCallback(
    (uploadedFiles) => {
      const alreadyAcceptedFiles = getFiles()
      const newFilesTotalCount =
        uploadedFiles.length + alreadyAcceptedFiles.length
      if (maxFilesCount && newFilesTotalCount > maxFilesCount) {
        return false
      }
      const uploadedFilesWithClientIds = uploadedFiles.map((file) => ({
        file,
        clientId: uuidv4(),
        isLoading: true,
        name: file.name,
        ...(hasAbortController
          ? { abortController: new window.AbortController() }
          : {}),
      }))
      setFiles([...alreadyAcceptedFiles, ...uploadedFilesWithClientIds])
      return true
    },
    [getFiles, maxFilesCount, setFiles, hasAbortController]
  )

  const updateFile = useCallback(
    ({ clientId, itemToUpdate }) => {
      const updatedFileIndex = getFiles().findIndex(
        (file) => file.clientId === clientId
      )
      const updatedFile = {
        ...getFiles()[updatedFileIndex],
        ...itemToUpdate,
      }
      setFiles(set([updatedFileIndex], updatedFile, getFiles()))
    },
    [getFiles, setFiles]
  )

  const getFileByClientId = useCallback(
    (clientId) => getFiles().find((file) => file.clientId === clientId),
    [getFiles]
  )

  const removeFile = useCallback(
    (clientId) => {
      setFiles(getFiles().filter((file) => file.clientId !== clientId))
    },
    [getFiles, setFiles]
  )

  return {
    getFileByClientId,
    getFiles,
    removeFile,
    setFiles,
    setInitialFiles,
    setNewFilesToUpload,
    updateFile,
  }
}
