import { useEffect, useRef, useState } from 'react'

export const usePortalVisibility = ({
  setComponentStateOnExit,
  isOutsideClickDisabled = false,
  initialIsVisible = false,
}: {
  setComponentStateOnExit?: () => void
  isOutsideClickDisabled?: boolean
  initialIsVisible?: boolean
} = {}) => {
  const [isPortalVisible, setIsPortalVisible] = useState(initialIsVisible)
  const portalRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isOutsideClickDisabled &&
        portalRef.current &&
        !portalRef.current.contains(event.target)
      ) {
        setIsPortalVisible(false)
        setComponentStateOnExit?.()
      }
    }
    if (isPortalVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [
    portalRef,
    setComponentStateOnExit,
    isPortalVisible,
    isOutsideClickDisabled,
  ])

  return {
    portalRef,
    isPortalVisible,
    setIsPortalVisible,
  }
}
