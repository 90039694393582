import { type MutableRefObject, useEffect, useState } from 'react'

export const useIsOverflow = (
  ref: MutableRefObject<any>,
  callback: (isOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState(undefined)

  useEffect(() => {
    const trigger = () => {
      const isElementOverflowing =
        ref.current?.offsetWidth < ref.current?.scrollWidth ||
        ref.current?.offsetHeight < ref.current?.scrollHeight

      if (isOverflow !== isElementOverflowing) {
        setIsOverflow(isElementOverflowing)
      }

      if (callback) {
        callback(isElementOverflowing)
      }
    }

    let observer: ResizeObserver | undefined
    if (ref.current) {
      if ('ResizeObserver' in window) {
        observer = new ResizeObserver(trigger)
        observer.observe(ref.current)
      }

      trigger()
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [callback, isOverflow, ref])

  return isOverflow
}
