import { gql, useMutation } from '@apollo/client'
import { CardElement } from '@stripe/react-stripe-js'
import { useCallback } from 'react'

const SETUP_PAYMENT_MUTATION = gql`
  mutation setupExpertUserPaymentMethod {
    setupExpertUserPaymentMethod
  }
`

export const useSetupExpertUserPaymentMethodMutation = (): [
  ({ name, stripe, elements }) => Promise<any>,
  { isLoading: boolean },
] => {
  const [executeSetupPaymentMutation, { loading: isLoading }] = useMutation(
    SETUP_PAYMENT_MUTATION,
    {}
  )

  const execute = useCallback(
    async ({ name, stripe, elements }) => {
      const {
        data: { setupExpertUserPaymentMethod },
      } = await executeSetupPaymentMutation()

      return stripe.confirmCardSetup(setupExpertUserPaymentMethod, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      })
    },
    [executeSetupPaymentMutation]
  )

  return [
    execute,
    {
      isLoading,
    },
  ]
}
