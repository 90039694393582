import {
  type ApolloError,
  type ApolloQueryResult,
  type DocumentNode,
  type QueryHookOptions,
  useQuery,
} from '@apollo/client'
import { useCallback, useState } from 'react'

interface UseFetchNextPageInput<QueryDataType = any, QueryInputType = any>
  extends QueryHookOptions<QueryDataType, QueryInputType> {
  currentPage?: number
  pageSize: number
  variablesFactory: (params: { page: number; limit: number }) => any
}

interface UseFetchNextPage<QueryDataType = any, QueryInputType = any> {
  page: number
  data?: QueryDataType
  previousData?: QueryDataType
  error?: ApolloError
  loading: boolean
  fetchNextPage: () => Promise<void>
  refetch: (
    variables?: Partial<QueryInputType>
  ) => Promise<ApolloQueryResult<QueryDataType>>
}

/**
 * Handles queries which use `pageLimitPagination` and provides `fetchNextPage` to handle `fetchMore` functionality.
 *
 * Controlled: (`currentPage` is `defined`)
 * `useQuery` will be called with `currentPage` multiplied by `pageSize` as `limit` and `fetchMore` will be called with the `currentPage` increased by `1`
 *
 * Uncontrolled: (`currentPage` is `undefined`)
 * `useQuery` will be called with `page` state multiplied by `pageSize` as `limit` and `fetchMore` will be called with the `page` state increased by `1`,
 * after `fetchMore` the `page` state is increased by `1` to be used with the new value in next call
 *
 * @param {gql query} query
 * @param props
 * @param props.currentPage Provides the ability to make the number of displayed pages controllable.
 * @param props.pageSize Default number of items on page
 * @param props.variablesFactory Callback function which returns object of variables for query and has pagination object as a parameter
 */
export const useFetchNextPageQuery = <
  QueryDataType = any,
  QueryInputType = any,
>(
  query: DocumentNode,
  {
    currentPage,
    pageSize,
    variablesFactory,
    ...rest
  }: UseFetchNextPageInput<QueryDataType, QueryInputType>
): UseFetchNextPage<QueryDataType, QueryInputType> => {
  const [page, setPage] = useState(1)

  const totalProductCount = (currentPage ?? page) * pageSize

  const { data, previousData, error, loading, fetchMore, refetch } = useQuery(
    query,
    {
      variables: variablesFactory({
        page: 1,
        limit: totalProductCount,
      }),
      notifyOnNetworkStatusChange: true,
      ...rest,
    }
  )

  const fetchNextPage = useCallback(async () => {
    await fetchMore({
      variables: variablesFactory({
        page: (currentPage ?? page) + 1,
        limit: pageSize,
      }),
    })
    if (!currentPage) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [fetchMore, variablesFactory, currentPage, page, pageSize])

  return {
    page,
    data,
    previousData,
    error,
    loading,
    fetchNextPage,
    refetch,
  }
}
