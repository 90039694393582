import { useState, useEffect, useRef } from 'react'

export const useIsVisible = ({
  initialIsVisible,
  isOutsideClickDisabled,
  whitelistOutsideClickSiblingElements,
}: {
  initialIsVisible: boolean
  isOutsideClickDisabled?: boolean
  whitelistOutsideClickSiblingElements?: boolean
}) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    // If ref.current has retry method, it means that reference to the component is not resolved yet
    // i.e dynamically loaded components
    if (isOutsideClickDisabled || !isVisible || !ref || ref?.current?.retry) {
      return
    }

    if (whitelistOutsideClickSiblingElements) {
      // Find all elements that are at the same level as the one where the ref is applied
      const siblingElements = Array.from(
        ref.current?.parentElement.childNodes || []
      ).filter((node) => node !== ref.current) as ChildNode[]

      const isSiblingElement = siblingElements.some((node) =>
        // @ts-ignore EventTarget is subtype of Node type
        node.contains(event.target)
      )

      if (isSiblingElement) {
        return
      }
    }

    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isVisible, setIsVisible }
}
