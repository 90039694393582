import { useCallback } from 'react'
import { useUserAuth } from 'providers/userAuth'
import { shouldRenderExpertContent as shouldRenderExpertContentBase } from 'utils/shouldRenderExpertContent'

export const useShouldRenderExpertContent = () => {
  const { user } = useUserAuth()

  const shouldRenderExpertContent = useCallback(
    (key: string | object, featureFlags = []) =>
      shouldRenderExpertContentBase({
        key,
        capabilities: user?.capabilities ?? [],
        featureFlags:
          user?.featureFlags?.map(({ label }) => label) ?? featureFlags,
      }),
    [user]
  )

  return { shouldRenderExpertContent }
}
