import { useCallback } from 'react'
import { useSetupClientPaymentMethodMutation } from '@client/hooks'
import { useSetupExpertUserPaymentMethodMutation } from '@expert/hooks'

export const useGetBuyerPaymentMethod = ({
  stripe,
  elements,
  user,
  isExpertSignedIn,
}) => {
  const [executeClientSetupPaymentMethodMutation] =
    useSetupClientPaymentMethodMutation()
  const [executeExpertUserSetupPaymentMethodMutation] =
    useSetupExpertUserPaymentMethodMutation()

  return useCallback(
    async (card) => {
      if (card && typeof card === 'object') {
        return card.stripeId
      }

      if (!stripe || !elements || !user) {
        return null
      }

      const result = isExpertSignedIn
        ? await executeExpertUserSetupPaymentMethodMutation({
            name: `${user.firstName} ${user.lastName}`,
            stripe,
            elements,
          })
        : await executeClientSetupPaymentMethodMutation({
            name: `${user.firstName} ${user.lastName}`,
            stripe,
            elements,
          })

      if (!result.error) {
        return result.setupIntent.payment_method
      }
      return null
    },
    [
      stripe,
      elements,
      user,
      executeClientSetupPaymentMethodMutation,
      executeExpertUserSetupPaymentMethodMutation,
      isExpertSignedIn,
    ]
  )
}
