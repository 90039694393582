import {
  type EXPERT_USER_CAPABILITIES,
  EXPERT_FEATURES_DEFINITION,
} from 'shared-constants'

export const shouldRenderExpertContent = ({
  key,
  capabilities = [],
  featureFlags = [],
}: {
  key: string | object
  capabilities: (keyof typeof EXPERT_USER_CAPABILITIES)[]
  featureFlags?: any[]
}) => {
  const definition = EXPERT_FEATURES_DEFINITION.get(key)

  if (!definition) {
    return false
  }
  const { requiredCapabilities, requiredFeatureFlags } = definition

  // Check if all of the required capabilities are present in the user's capabilities
  const hasRequiredCapabilities = requiredCapabilities.length
    ? requiredCapabilities.every(
        (capability: keyof typeof EXPERT_USER_CAPABILITIES) =>
          capabilities.includes(capability)
      )
    : true

  // Check if any of the required feature flags are present in the user's feature flags
  const hasRequiredFeatureFlags = requiredFeatureFlags.length
    ? requiredFeatureFlags.every((flag) => featureFlags.includes(flag))
    : true

  return hasRequiredCapabilities && hasRequiredFeatureFlags
}
