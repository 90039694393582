import { type OperationVariables, useQuery } from '@apollo/client'
import { useCallback, useState } from 'react'

type FetchNextCursorQueryOptions<T> = {
  limit?: number
  variablesFactory?: (vars: {
    limit: number
    after: string
  }) => Partial<OperationVariables>
  getAfterFromResponse?: (data: T) => void
} & Parameters<typeof useQuery<T>>[1]

export const useFetchNextCursorQuery = <T>(
  query: Parameters<typeof useQuery<T>>[0],
  options: FetchNextCursorQueryOptions<T> = {}
) => {
  const { limit, variablesFactory, getAfterFromResponse, ...rest } = options

  const [after, setAfter] = useState(null)
  const { fetchMore, refetch, ...restQuery } = useQuery<T>(query, {
    variables: variablesFactory?.({
      limit,
      after,
    }),
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: T) => {
      setAfter(getAfterFromResponse?.(response))
    },
    ...rest,
  })

  const fetchNextPage = useCallback(async () => {
    await fetchMore({
      variables: variablesFactory?.({
        limit,
        after,
      }),
    })
  }, [fetchMore, variablesFactory, limit, after])

  const resetPagination = useCallback(() => {
    setAfter(null)
  }, [])

  return {
    fetchNextPage,
    resetPagination,
    refetch,
    ...restQuery,
  }
}
