import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useUnloadPage = (onUnloadPageCallback: () => void) => {
  const { events } = useRouter()

  useEffect(() => {
    const handleRouteChange = (
      _: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow) {
        onUnloadPageCallback()
      }
    }
    window.addEventListener('beforeunload', onUnloadPageCallback)
    events.on('routeChangeStart', handleRouteChange)
    return () => {
      window.removeEventListener('beforeunload', onUnloadPageCallback)
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [events, onUnloadPageCallback])
}
